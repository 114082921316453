import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import EntryPage from './elements/EntryPage';
import './App.css';

const Articles = () => <div className="page">Articles about Colonial North America</div>;
const Authors = () => <div className="page">Authors will be listed here</div>;
const About = () => <div className="page">About our Colonial North America Blog</div>;

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<EntryPage />} />
          <Route path="/articles" element={<Articles />} />
          <Route path="/authors" element={<Authors />} />
          <Route path="/about" element={<About />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;