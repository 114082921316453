import React from 'react';
import { Link } from 'react-router-dom';
import './EntryPage.css';

function EntryPage() {
  return (
    <div className="entrypage">
      <div className="content">
        <h2>BRITISH NORTH AMERICA</h2>
        <nav>
          <ul>
            <li><Link to="/articles">Articles</Link></li>
            <li><Link to="/authors">Authors</Link></li>
            <li><Link to="/about">About</Link></li>
          </ul>
        </nav>
      </div>
    </div>
  );
}

export default EntryPage;